<template>
  <div class="save-seance-template-modal" v-if="hasPerm('youth.change_seancetemplate')">
    <b-modal
      size="xl"
      id="bv-modal-edit-seance-template"
      cancel-title="Annuler"
      @ok="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="!isValid"
    >
      <template v-slot:modal-title>
        <span v-if="seanceTemplate.id">Edition {{ seanceTemplate.name }}</span>
        <span v-else>Ajout d'un nouveau gabarit de séance</span>
      </template>
      <b-form ref="form" v-if="youthHome && seanceType">
        <b-row class="form-line">
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  :label="'N° ' + youthHomeLabel"
                  label-for="youth-home"
                  description=""
                >
                  <b-input id="youth-home" disabled :value="youthHomeNumber"></b-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="'Autres ' + youthHomeLabel"
                  label-for="extra-youth-homes"
                  description="N° des accueils séparés par des virgules. Ce gabarit sera aussi disponible sur ces accueils"
                >
                  <b-input id="extra-youth-homes" v-model="extraYouthHomeNumbers">
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="form-line">
              <b-col>
                <b-form-group
                  label="Type"
                  label-for="type"
                  description=""
                >
                  <b-input id="type" disabled :value="seanceType.name"></b-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Code"
                  label-for="code"
                  description=""
                >
                  <b-form-input type="text" id="code" v-model="seanceTemplateCode"></b-form-input>
                  <div v-if="isCodeExisting" class="warning-line">
                    Ce code est déjà utilisé
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              label="Nom"
              label-for="name"
              description=""
            >
              <b-form-input type="text" id="name" v-model="seanceTemplateName"></b-form-input>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group
                  label="Type de séance"
                  label-for="excursion"
                  description="Standard / Sorties / Séjours"
                >
                  <b-form-select
                    id="excursion"
                    v-model="excursion"
                  >
                    <b-select-option v-for="elt of excursionChoices" :key="elt.id" :value="elt.id">
                      {{ elt.name }}
                    </b-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="group-isReward"
                  label-for="isReward"
                  description="le montant n'est pas dû mais crédité sur une cagnotte"
                >
                  <br />
                  <b-form-checkbox
                    id="isReward"
                    v-model="isReward"
                    name="isReward"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Chantier
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="group-forceTariffType"
                  label-for="forceTariffType"
                >
                  <b-form-checkbox
                    id="forceTariffType"
                    v-model="forceTariffType"
                    name="forceTariffType"
                  >
                    Personnaliser le type de tarif
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-select
                  id="customTariffType"
                  v-model="customTariffType"
                  :disabled="!forceTariffType"
                >
                  <b-select-option v-for="elt of tariffTypeChoices" :key="elt.id" :value="elt.id">
                    {{ elt.name }}
                  </b-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="group-noStats"
                  label-for="noStats"
                  description="Les inscriptions ne seront pas comptabilisées"
                >
                  <b-form-checkbox
                    id="noStats"
                    v-model="noStats"
                    name="noStats"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Hors stats fréquentations
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-form-group
              label="Communes"
              label-for="citySpecific"
              description=""
            >
              <b-form-select
                id="citySpecific"
                v-model="citySpecific"
                :disabled="citySpecificChoices.length <= 1"
              >
                <b-select-option v-for="elt of citySpecificChoices" :key="elt.id" :value="elt">
                  {{ elt.name }}
                </b-select-option>
              </b-form-select>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group
                  label="Nombre de jours"
                  label-for="daysNumber"
                  :description="daysNumberDescription"
                >
                  <b-form-input
                    id="daysNumber"
                    v-model="daysNumber"
                    type="number"
                    step="1"
                    min="1"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Durée"
                  label-for="duration"
                  description="en heures (pour 1 journée)"
                >
                  <b-form-input
                    type="number"
                    min="0"
                    id="duration"
                    v-model="duration"
                    :disabled="fixedFee"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="group-fixedFee"
                  label-for="fixedFee"
                  description="séance fictive ouvrant gratuité pour l'inscription aux séances liées"
                >
                  <br />
                  <b-form-checkbox
                    id="fixedFee"
                    v-model="fixedFee"
                    name="fixedFee"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Forfait
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="fixedFee">
              <b-col>
                <b-form-group
                  id="group-fixedFeeAuto"
                  label-for="fixedFeeAuto"
                  description="L'inscription au forfait inscrira automatiquement à toutes les séances du forfait"
                >
                  <br />
                  <b-form-checkbox
                    id="fixedFeeAuto"
                    v-model="fixedFeeAuto"
                    name="fixedFeeAuto"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Inscriptions automatiques aux séances du forfait
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Code des séances du forfait"
                  label-for="fixedFeeCodes"
                  :description="fixedFeeCodesLabel"
                >
                  <b-form-input type="text" id="fixedFeeCodes" v-model="fixedFeeCodes">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              label=""
              label-for="moments"
              description="Pique-Nique = repas fourni par la famille"
            >
              <br />
              <check-box-select
                :choices="moments"
                :inline="true"
                :hide-all="true"
                @changed="onSelectedMomentsChanged($event)"
                :initial-value="initialMoments"
                :disabled-choices="disabledMoments"
                id="templateMoments"
              >
              </check-box-select>
            </b-form-group>
            <b-form-group
              label="Modèles CAF"
              label-for="caf-models"
              :description="loadingCaf ? 'chargement en cours' : 'dossier CAF'"
            >
              <b-select
                id="caf-models"
                v-model="selectedCafModel"
                :disabled="!isCafModelActive || loadingCaf"
              >
                <b-select-option v-for="elt of cafModels" :key="elt.id" :value="elt">
                  {{ elt.fullName() }}
                </b-select-option>
              </b-select>
              <div class="caf-detail" v-if="selectedCafModel">
                <div class="caf-detail-item" v-if="selectedCafModel.convention.description">
                  {{ selectedCafModel.convention.description }}
                </div>
                <div class="caf-detail-item" v-if="selectedCafModel.seanceStartsAt || selectedCafModel.seanceEndsAt">
                  Matin: de {{ selectedCafModel.seanceStartsAt | defaultValue('-') }}
                  à {{ selectedCafModel.seanceEndsAt | defaultValue('-') }}
                </div>
                <div class="caf-detail-item" v-if="selectedCafModel.seanceStartsAt2 || selectedCafModel.seanceEndsAt2">
                  Après-midi de {{ selectedCafModel.seanceStartsAt2 | defaultValue('-') }}
                  à {{ selectedCafModel.seanceEndsAt2 | defaultValue('-') }}
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Commentaires"
              label-for="comments"
              description=""
            >
              <b-form-input
                id="comments"
                v-model="comments"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Préférences"
              label-for="preference"
              description="Permet à la famille d'indiquer une préférence entre plusieurs séances"
            >
              <b-form-input
                id="preference"
                v-model="preferenceGroup"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Ordre"
              label-for="order"
              description="Tri croissant des séances. Matin(-10), Repas(0), Après-Midi(10), Soir(20)"
            >
              <b-form-input
                id="order"
                type="number"
                step="1"
                min="0"
                v-model="order"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Alias"
              label-for="alias"
              description="Code pour affichage grille. Par défaut, code du gabarit"
            >
              <b-form-input
                id="alias"
                v-model="alias"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import {
  CitySpecific, makeSeanceTemplate, citySpecificDisplay, getDayTimes, getSelectedDayTimes, DayTimeValues,
  isDayTimeSelected, getExcursionChoices, getTariffTypeLabel, TariffType
} from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { makeCafModel } from '@/types/reports'
import store from '@/store'

export default {
  name: 'SeanceTemplateEditModal',
  components: {
    CheckBoxSelect,
  },
  mixins: [BackendMixin],
  props: {
    seanceTemplate: Object,
    youthHome: Object,
    seanceType: Object,
    templates: Array,
  },
  data() {
    return {
      seanceTemplateName: '',
      seanceTemplateCode: '',
      comments: '',
      isSaveActive: true,
      selectedPeriod: null,
      citySpecificChoices: [],
      citySpecific: null,
      duration: null,
      moments: [],
      initialMoments: [],
      selectedMoments: [],
      extraYouthHomeNumbers: '',
      daysNumber: 1,
      selectedCafModel: null,
      cafModels: [],
      templateId: 0,
      order: 0,
      alias: '',
      loadingCaf: false,
      fixedFee: false,
      fixedFeeAuto: false,
      fixedFeeCodes: '',
      isReward: false,
      noStats: false,
      excursion: 0,
      excursionChoices: getExcursionChoices(),
      forceTariffType: false,
      customTariffType: 0,
      preferenceGroup: '',
    }
  },
  watch: {
    seanceTemplate: function(seanceTemplate) {
      this.initValues(seanceTemplate)
    },
    youthHome: function() { },
    seanceType: function() { },
    seanceCode: function(code) {},
    fixedFee: function(fixedFee) {
      if (fixedFee) {
        this.resetCafModel()
        this.duration = 0
      }
    },
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    hasExternalCities() {
      return store.getters.config.hasExternalCities
    },
    daysNumberDescription() {
      if (this.fixedFee) {
        return 'Durée du forfait'
      } else {
        return 'Pour les séjours (peut être modifié lors de la création de la séance)'
      }
    },
    fixedFeeCodesLabel() {
      const days = '' + this.daysNumber + (this.daysNumber > 1 ? ' jours' : ' jour')
      return (
        'Codes séparés par des virgules. ' +
        'Lors de la création groupée, Les séances créés avec ce code et jusqu\'à ' +
        days + ' après la date du forfait seront incluses dans le forfait'
      )
    },
    disabledMoments() {
      if (!isDayTimeSelected(DayTimeValues.Lunch, this.selectedMoments)) {
        return [DayTimeValues.Picnic]
      }
      return []
    },
    youthHomeNumber() {
      if (this.seanceTemplate.id) {
        return this.seanceTemplate.youthHomeNumber
      } else {
        return this.youthHome.number
      }
    },
    isValid() {
      let extraYouthHomeNumbersValid = true
      if (this.extraYouthHomeNumbers) {
        const numbersSeparatedByComasRegex = /^[0-9]+(,[0-9]+)*$/g
        extraYouthHomeNumbersValid = this.extraYouthHomeNumbers.match(numbersSeparatedByComasRegex)
      }
      return (
        this.seanceTemplateCode &&
        this.citySpecific !== null &&
        this.duration !== null &&
        this.seanceTemplateName &&
        this.selectedCafModel !== null &&
        !this.isCodeExisting &&
        extraYouthHomeNumbersValid
      )
    },
    isCodeExisting() {
      return this.templates.filter(
        elt => elt.id !== this.seanceTemplate.id
      ).map(
        elt => elt.code
      ).indexOf(this.seanceTemplateCode) >= 0
    },
    canViewCafModel() {
      return this.hasPerm('reports.view_cafmodel')
    },
    canChangeCafModel() {
      return this.hasPerm('reports.change_cafmodel')
    },
    isCafModelActive() {
      return this.canChangeCafModel && !this.fixedFee
    },
    tariffTypeChoices() {
      return [TariffType.SEANCE_TARIFF, TariffType.HOURLY_TARIFF, TariffType.CLOCK_TARIFF].map(
        elt => makeChoice({ id: elt, name: getTariffTypeLabel(elt), })
      )
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    resetCafModel() {
      const matching = this.cafModels.filter(elt => elt.id === 0)
      if (matching.length) {
        this.selectedCafModel = matching[0]
      }
    },
    async initValues(seanceTemplate) {
      this.templateId = seanceTemplate.id
      if (seanceTemplate.id === 0) {
        this.seanceTemplateName = ''
        this.seanceTemplateCode = ''
        this.duration = null
        this.comments = ''
        if (this.citySpecificChoices.length === 1) {
          this.citySpecific = this.citySpecificChoices[0]
        } else {
          this.citySpecific = null
        }
        this.initialMoments = []
        this.selectedMoments = []
        this.extraYouthHomeNumbers = ''
        this.daysNumber = 1
        this.order = 0
        this.alias = ''
        this.selectedCafModel = null
        this.fixedFee = false
        this.fixedFeeAuto = false
        this.fixedFeeCodes = ''
        this.isReward = false
        this.noStats = false
        this.excursion = 0
        this.forceTariffType = false
        this.customTariffType = this.seanceType ? this.seanceType.tariffType : TariffType.SEANCE_TARIFF
        await this.loadCafModels()
        this.loadingCaf = false
        this.preferenceGroup = ''
      } else {
        this.seanceTemplateName = this.seanceTemplate.name
        this.seanceTemplateCode = this.seanceTemplate.code
        this.duration = this.seanceTemplate.duration
        this.comments = this.seanceTemplate.comments
        this.extraYouthHomeNumbers = this.seanceTemplate.extraYouthHomeNumbers
        this.daysNumber = this.seanceTemplate.daysNumber
        this.order = this.seanceTemplate.order
        this.alias = this.seanceTemplate.alias
        this.fixedFee = this.seanceTemplate.fixedFee
        this.fixedFeeAuto = this.seanceTemplate.fixedFeeAuto
        this.fixedFeeCodes = this.seanceTemplate.fixedFeeCodes
        this.preferenceGroup = this.seanceTemplate.preferenceGroup
        this.isReward = this.seanceTemplate.isReward
        this.noStats = this.seanceTemplate.noStats
        this.excursion = this.seanceTemplate.excursion
        this.forceTariffType = this.seanceTemplate.forceTariffType
        if (this.forceTariffType) {
          this.customTariffType = this.seanceTemplate.customTariffType
        } else {
          this.customTariffType = this.seanceType ? this.seanceType.tariffType : TariffType.SEANCE_TARIFF
        }
        this.citySpecific = makeChoice(
          {
            id: seanceTemplate.citySpecific,
            name: citySpecificDisplay(seanceTemplate.citySpecific),
          }
        )
        this.initialMoments = getSelectedDayTimes(seanceTemplate)
        this.selectedMoments = this.initialMoments
        this.loadingCaf = true
        this.selectedCafModel = null
        await this.loadCafModels()
        await this.loadTemplateCafModel()
        this.loadingCaf = false
      }
    },
    async onSave() {
      await this.saveTemplate()
      await this.saveCafModel()
    },
    async saveTemplate() {
      this.seances = []
      let backendApi = null
      let url = '/api/youth/seance-templates/'
      let data = {}
      data.morning = isDayTimeSelected(DayTimeValues.Morning, this.selectedMoments)
      data.lunch = isDayTimeSelected(DayTimeValues.Lunch, this.selectedMoments)
      data.picnic = isDayTimeSelected(DayTimeValues.Picnic, this.selectedMoments)
      data.afternoon = isDayTimeSelected(DayTimeValues.Afternoon, this.selectedMoments)
      data.evening = isDayTimeSelected(DayTimeValues.Evening, this.selectedMoments)
      data.name = this.seanceTemplateName
      data.code = this.seanceTemplateCode
      data.city_specific = this.citySpecific.id
      data.extra_youth_home_numbers = this.extraYouthHomeNumbers
      data.duration = this.duration
      data.days_number = this.daysNumber
      data.order = this.order
      data.alias = this.alias
      data.fixed_fee = this.fixedFee
      data.fixed_fee_auto = this.fixedFeeAuto
      data.fixed_fee_codes = this.fixedFeeCodes
      data.is_reward = this.isReward
      data.no_stats = this.noStats
      data.excursion = this.excursion
      data.force_tariff_type = this.forceTariffType
      data.custom_tariff_type = this.customTariffType
      data.preference_group = this.preferenceGroup
      if (this.seanceTemplate.id) {
        url += '' + this.seanceTemplate.id + '/'
        backendApi = new BackendApi('patch', url)
      } else {
        data.seance_type = this.seanceType.id
        data.youth_home_number = this.youthHomeNumber
        backendApi = new BackendApi('post', url)
      }
      try {
        let resp = await backendApi.callApi(data)
        let seanceTemplate = makeSeanceTemplate(resp.data)
        let successText = ''
        if (this.seanceTemplate.id) {
          this.$emit('updated', seanceTemplate)
          successText = 'Le gabarit de séance a été modifié'
        } else {
          this.$emit('added', seanceTemplate)
          successText = 'Le gabarit de séance a été ajouté'
        }
        this.templateId = seanceTemplate.id
        await this.addSuccess(successText)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async saveCafModel() {
      if (this.canChangeCafModel) {
        const url = '/reports/api/caf-model/seance-template/' + this.templateId + '/'
        const backendApi = new BackendApi('post', url)
        let data = {
          'caf_model': this.selectedCafModel ? this.selectedCafModel.id : 0,
        }
        try {
          await backendApi.callApi(data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadCafModels() {
      const url = '/reports/api/caf-models/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.cafModels = [makeCafModel({ id: 0, })].concat(
          resp.data.map(
            elt => {
              return makeCafModel(elt)
            }
          )
        )
      } catch (err) {
        await this.addError('Modèle CAF: ' + this.getErrorText(err))
      }
    },
    async loadTemplateCafModel() {
      this.selectedCafModel = null
      if (this.seanceTemplate.id) {
        const url = '/reports/api/caf-model/seance-template/' + this.seanceTemplate.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const cafModel = makeCafModel(resp.data['caf_model'])
          const cafModelIndex = this.cafModels.map(elt => elt.id).indexOf(cafModel.id)
          if (cafModelIndex >= 0) {
            this.selectedCafModel = this.cafModels[cafModelIndex]
          } else {
            this.cafModels.push(cafModel)
            this.selectedCafModel = cafModel
          }
        } catch (err) {
          await this.addError('Modèle CAF: ' + this.getErrorText(err))
        }
      }
    },
    onSelectedMomentsChanged(event) {
      this.selectedMoments = event.choices
    },
  },
  created() {
    let citySpecificValues
    if (this.hasExternalCities) {
      citySpecificValues = [CitySpecific.NA, CitySpecific.No, CitySpecific.Yes]
    } else {
      citySpecificValues = [CitySpecific.NA]
    }
    this.citySpecificChoices = citySpecificValues.map(
      val => makeChoice({ id: val, name: citySpecificDisplay(val), })
    )
    this.moments = getDayTimes().map(elt => makeChoice(elt))
    this.initValues(this.seanceTemplate)
  },
}
</script>
<style lang="less" scoped>
.form-group:last-of-type {
  border-bottom: none;
}
.caf-detail {
  font-size: 12px;
  color: #222;
}
.caf-detail-item {
  display: inline-block;
  padding: 3px;
  background: #eee;
  border: solid 1px #ccc;
  margin-right: 10px;
  margin-top: 3px;
}
</style>
