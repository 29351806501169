<template>
  <div class="seance-limits-modal" v-if="hasPerm('youth.view_seancelimit')">
    <b-modal
      size="xl"
      id="bv-modal-seance-limits"
      cancel-title="Annuler"
      @cancel="onCancel"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      :ok-disabled="!isSaveActive || !canChange"
    >
      <template v-slot:modal-title>
        <span v-if="seance.id">Limites {{ seance.name }}</span>
      </template>
      <b-form ref="form" v-if="seanceLimits">
        <b-row>
          <b-col class="col-border">
            <b-row>
              <b-col>
                <h4>Limites</h4>
              </b-col>
              <b-col class="text-right">
                <a
                  v-if="canChange"
                  href
                  class="btn btn-primary btn-sm ut-add-limit"
                  @click.prevent="addLimit()"
                >
                  <i class="fa fa-plus"></i>
                </a>
              </b-col>
            </b-row>
            <b-row v-if="seanceLimits.limits.length === 0">
              <b-col><div class="empty-text">Aucune limite n'est définie</div></b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-for="limit of seanceLimits.limits"
                  :key="limit.id"
                  class="limit-line"
                  :class="{ 'selected': limit === selectedLimit, 'override': limit.overrideOthers, }"
                  @click="selectLimit(limit)"
                >
                  <b-row>
                    <b-col cols="5">
                      {{ getLimitTypeName(limit.limitType) }}
                    </b-col>
                    <b-col cols="6">
                      <div v-html="htmlText(limit.getScopeName())"></div>
                    </b-col>
                    <b-col cols="1" class="text-right">
                      <a
                        v-if="canChange"
                        class="btn btn-danger btn-sm"
                        style="padding: 3px 6px; font-size: 12px; margin-top: -3px"
                        @click.prevent="removeLimit(limit)"
                      >
                        <i class="fa fa-times"></i>
                      </a>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <div v-if="selectedLimit !== null" class="limit-detail">
              <b-form-group
                label="Type de limite"
                label-for="limit_type"
                description="Définit à quelles séances sera appliquée cette limite"
              >
                <b-select
                  :disabled="!canChange"
                  id="limit_type"
                  name="limit_type"
                  v-model="selectedLimit.limitType"
                  @change="onLimitTypeChange()"
                >
                  <b-select-option v-for="limitType of allLimitTypes" :key="limitType" :value="limitType">
                    {{ getLimitTypeName(limitType) }}
                  </b-select-option>
                </b-select>
              </b-form-group>
              <b-form-group
                label="Priorité"
                label-for="overrideOthers"
                description="Les autres limites associées à la séance ne seront pas prises en compte"
              >
                <b-form-checkbox
                  :disabled="!canChange || !canOverride"
                  id="overrideOthers"
                  name="overrideOthers"
                  v-model="selectedLimit.overrideOthers"
                  @change="onChange()"
                >
                  Remplace les autres limites associées à la séance
                </b-form-checkbox>
              </b-form-group>
              <div class="limit-numbers">
                <b-row>
                  <b-col>
                    <b-checkbox v-if="!onlyMorning" id="sameForDay" v-model="sameForDay" style="font-size: 10px;">
                      Identique pour la journée
                    </b-checkbox>
                  </b-col>
                  <b-col v-if="onlyMorning"><b>Séance</b></b-col>
                  <b-col v-if="!onlyMorning"><b>Matin</b></b-col>
                  <b-col><b v-if="!onlyMorning">Repas</b></b-col>
                  <b-col><b v-if="!onlyMorning">Après-midi</b></b-col>
                </b-row>
                <div class="row-line">
                  <b-row>
                    <b-col cols="3">
                      <div class="col-title">Pour tous</div>
                    </b-col>
                    <b-col cols="3">
                      <b-input
                        id="all-groups1"
                        :disabled="readOnly"
                        type="number"
                        step="1"
                        min="0"
                        v-model="selectedLimit.morningMax"
                        @change="onChangeNumber()"
                      ></b-input>
                    </b-col>
                    <b-col cols="3">
                      <b-input
                        id="all-groups2"
                        :disabled="readOnly2"
                        type="number"
                        step="1"
                        min="0"
                        v-model="selectedLimit.lunchMax"
                        @change="onChange()"
                        v-if="!onlyMorning"
                      ></b-input>
                    </b-col>
                    <b-col cols="3">
                      <b-input
                        id="all-groups3"
                        :disabled="readOnly2"
                        type="number"
                        step="1"
                        min="0"
                        v-model="selectedLimit.afternoonMax"
                        @change="onChange()"
                        v-if="!onlyMorning"
                      ></b-input>
                    </b-col>
                  </b-row>
                  <div>
                    <b-form-checkbox
                      class="small-text"
                      id="all-groups0"
                      v-model="selectedLimit.isBlocked"
                      :disabled="!canChange"
                      @change="onChange()"
                    >
                      interdire l'inscription pour tous
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row-line" v-for="ageGroup of selectedLimit.ageGroups" :key="ageGroup.groupId">
                  <b-row>
                    <b-col cols="3">
                      <div class="col-title">{{ ageGroup.groupName }}</div>
                    </b-col>
                    <b-col cols="3">
                      <b-input
                        :id="'gr-' + ageGroup.groupId + '-groups1'"
                        :disabled="isReadOnly(ageGroup)"
                        type="number"
                        step="1"
                        min="0"
                        v-model="ageGroup.morningMax"
                        @change="onChangeNumber()"
                      ></b-input>
                    </b-col>
                    <b-col cols="3">
                      <b-input
                        :id="'gr-' + ageGroup.id + '-groups2'"
                        :disabled="isReadOnly2(ageGroup)"
                        type="number"
                        step="1"
                        min="0"
                        v-model="ageGroup.lunchMax"
                        @change="onChange()"
                        v-if="!onlyMorning"
                      ></b-input>
                    </b-col>
                    <b-col cols="3">
                      <b-input
                        :id="'gr-' + ageGroup.groupId + '-groups3'"
                        :disabled="isReadOnly2(ageGroup)"
                        type="number"
                        step="1"
                        min="0"
                        v-model="ageGroup.afternoonMax"
                        @change="onChange()"
                        v-if="!onlyMorning"
                      ></b-input>
                    </b-col>
                  </b-row>
                  <div>
                    <b-form-checkbox
                      class="small-text"
                      :id="'gr-' + ageGroup.groupId + '-groups0'"
                      v-model="ageGroup.isBlocked"
                      :disabled="readOnly"
                      @change="onChange()"
                    >
                      interdire l'inscription à ce groupe d'âge
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="help-text3">
                <i class="fa fa-info-circle"></i> Si bloqué, l'inscription à la séance est impossible.
              </div>
              <div class="help-text3" v-if="selectedLimit.ageGroups.length && seance.youthHome.ageRuleName">
                <i class="fa fa-info-circle"></i> {{ seance.youthHome.ageRuleName }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import {
  getLimitTypeName, getLimitTypes, LimitType, makeSeanceLimit, makeSeanceLimits, makeAgeGroupSeanceLimit
} from '@/types/youth'
import { BackendMixin } from '@/mixins/backend'
import { htmlText } from '@/utils/html'

export default {
  name: 'SeanceLimitsModal',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    seance: Object,
    ageGroups: Object,
  },
  data() {
    return {
      isSaveActive: true,
      seanceLimits: null,
      nextLimitId: -1,
      selectedLimit: null,
      sameForDay: true,
    }
  },
  watch: {
    seance: function() { this.loadLimits() },
    ageGroups: function() { },
    canOverride: function() {
      if (!this.canOverride && this.selectedLimit) {
        this.selectedLimit.overrideOthers = false
      }
    },
  },
  computed: {
    allLimitTypes() {
      if (this.seance) {
        return getLimitTypes(this.seance.isExcursion())
      } else {
        return []
      }
    },
    canChange() {
      return this.hasPerm('youth.change_seancelimit')
    },
    readOnly() {
      return (
        (!this.canChange) ||
        (this.selectedLimit && this.selectedLimit.isBlocked)
      )
    },
    readOnly2() {
      return this.readOnly || this.sameForDay
    },
    canOverride() {
      return (
        (this.selectedLimit) &&
        (this.selectedLimit.limitType !== LimitType.LIMIT_SEANCE_TYPE_AND_PERIOD)
      )
    },
    onlyMorning() {
      return (
        (this.selectedLimit) &&
        (this.selectedLimit.limitType === LimitType.LIMIT_SEANCE)
      )
    },
  },
  methods: {
    htmlText,
    ...mapActions(['addSuccess', 'addError']),
    onCancel() {
      this.$emit('modal-closed', {})
    },
    async onSave() {
      this.isSaveActive = false
      let url = '/api/youth/seance-limits/' + this.seance.id + '/'
      const backendApi = new BackendApi('post', url)
      try {
        let data = []
        for (let limit of this.seanceLimits.limits) {
          let ageGroups = limit.ageGroups.map(
            elt => {
              return {
                age_group: elt.groupId,
                morning_max: elt.morningMax,
                lunch_max: elt.lunchMax,
                afternoon_max: elt.afternoonMax,
                is_blocked: elt.isBlocked,
              }
            }
          )
          let dataElt = {
            youth_home: this.seance.youthHome.id,
            limit_type: limit.limitType,
            morning_max: limit.morningMax,
            lunch_max: limit.lunchMax,
            afternoon_max: limit.afternoonMax,
            is_blocked: limit.isBlocked,
            override_others: limit.overrideOthers,
            age_groups: ageGroups,
          }
          data.push(dataElt)
        }
        const resp = await backendApi.callApi(data)
        this.$emit('refresh-required', {})
        if (resp.data.message) {
          await this.addSuccess(resp.data.message)
        }
        this.$bvModal.hide('bv-modal-seance-limits')
      } catch (err) {
        await this.addError(this.getErrorText(err))
        this.isSaveActive = true
      }
    },
    async loadLimits() {
      this.seanceLimits = null
      this.selectedLimit = null
      if (this.seance && this.seance.id) {
        let url = '/api/youth/seance-limits/' + this.seance.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.seanceLimits = makeSeanceLimits(resp.data)
          this.nextLimitId = -this.seanceLimits.limits.length - 1
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getLimitTypeName(limitType) {
      return getLimitTypeName(limitType, this.seance.seanceType.name, this.seance.period.name)
    },
    addLimit() {
      let limit = makeSeanceLimit(
        {
          seance_type: this.seance.seanceType.id,
          period: this.seance.period.id,
          seance: this.seance,
          day: this.seance.date,
        }
      )
      limit.id = this.nextLimitId
      this.nextLimitId -= 1
      limit.limitType = LimitType.LIMIT_INVALID
      const limitAgeGroups = this.ageGroups.getLimitAgeGroups(limit)
      for (let ageGroup of limitAgeGroups) {
        limit.ageGroups.push(
          makeAgeGroupSeanceLimit({
            'group_id': ageGroup.id,
            'start_age': ageGroup.startAge,
            'group_name': ageGroup.name,
          })
        )
      }
      this.seanceLimits.limits = this.seanceLimits.limits.concat([limit])
      this.selectedLimit = limit
      this.sameForDay = this.allSame(limit)
      this.isSaveActive = this.isValid()
    },
    allSame(limit) {
      if ((limit.morningMax === limit.lunchMax) && (limit.morningMax === limit.afternoonMax)) {
        for (const group of limit.ageGroups) {
          if ((group.morningMax !== group.lunchMax) || (group.morningMax === group.afternoonMax)) {
            return false
          }
        }
        return true
      }
      return false
    },
    removeLimit(limit) {
      let index = this.seanceLimits.limits.indexOf(limit)
      if (index >= 0) {
        this.seanceLimits.limits.splice(index, 1)
        this.seanceLimits.limits = this.seanceLimits.limits.concat([])
        this.selectedLimit = null
      }
      this.isSaveActive = this.isValid()
    },
    selectLimit(limit) {
      if (this.seanceLimits.limits.indexOf(limit) >= 0) {
        this.selectedLimit = limit
      } else {
        this.selectedLimit = null
      }
    },
    isValid() {
      for (let limit of this.seanceLimits.limits) {
        if (!limit.isValid()) {
          return false
        }
      }
      return true
    },
    onChange() {
      this.isSaveActive = this.isValid()
    },
    onChangeNumber() {
      this.setSame()
      this.onChange()
    },
    setSame() {
      if (this.sameForDay && !this.onlyMorning) {
        this.selectedLimit.afternoonMax = this.selectedLimit.morningMax
        this.selectedLimit.lunchMax = this.selectedLimit.morningMax
        for (const group of this.selectedLimit.ageGroups) {
          group.lunchMax = group.morningMax
          group.afternoonMax = group.morningMax
        }
      }
    },
    isReadOnly(ageGroup) {
      return this.readOnly || ageGroup.isBlocked
    },
    isReadOnly2(ageGroup) {
      return this.isReadOnly(ageGroup) || this.sameForDay
    },
    onLimitTypeChange() {
      const limitAgeGroups = this.ageGroups.getLimitAgeGroups(this.selectedLimit)
      this.selectedLimit.ageGroups = []
      for (let ageGroup of limitAgeGroups) {
        this.selectedLimit.ageGroups.push(
          makeAgeGroupSeanceLimit({
            'group_id': ageGroup.id,
            'start_age': ageGroup.startAge,
            'group_name': ageGroup.name,
          })
        )
      }
      this.onChange()
    },
  },
  mounted() {
    this.loadLimits()
  },
}
</script>
<style lang="less" scoped>
.limit-line {
  padding: 10px;
  margin-bottom: 10px;
  background: #f0f0f0;
  color: #222;
  cursor: pointer;
  font-size: 14px;
}
.limit-line.selected {
  background: #d0d0d0;
  color: #222;
}
.limit-line.override {
  background: #fbff8d;
  color: #222;
}
.limit-line.override.selected {
  background: #d3d777;
  color: #222;
}
.limit-numbers .row-line {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid 1px #d0d0d0;
}
.limit-numbers .col-title {
  font-weight: bold;
  margin-top: 5px;
  font-size: 14px;
}
.limit-detail {
  padding: 0 10px;
}
.limit-numbers input {
  font-size: 12px;
}
</style>
